// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { Typography } from '@mui/material'
import CheckCircleIcon from '../../../../../img/icon/CheckCircleIcon'
import VisibilityToggleIcon from '../../../../../img/icon/VisibilityToggleIcon'
import DatePicker from '../../../LocalElements/DatePicker/DatePicker'
import Input from '../../../../../ui/input/index.tsx'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { toggleEnableStep } from '../../../../../store/slices/app/controlers/stepper.js'

const ProtocolDate = props => {
  const { currentDate, nextDate, nextDateAutoGenerate, changeNextDate } = props
  const { width } = useWindowDimensions()
  const dispatch = useDispatch()

  const enableNextStep = useMemo(() => {
    if (currentDate && (nextDate || nextDateAutoGenerate)) return true
    else return false
  }, [currentDate, nextDate, nextDateAutoGenerate])

  useEffect(() => {
    dispatch(toggleEnableStep(enableNextStep))
  }, [enableNextStep])

  return (
    <React.Fragment>
      <h2 style={{ marginBottom: '10px' }}>Поверка проведена</h2>

      <div className='flexContainerWithGap' style={width <= 1050 ? { marginBottom: '0px' } : {}}>
        <div className='width32Percent'>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography mb={1}> Дата следующей поверки</Typography>
            <div>
              {nextDateAutoGenerate || nextDate ? (
                <CheckCircleIcon
                  sx={{
                    color: '#63E6BE',
                  }}
                />
              ) : (
                <VisibilityToggleIcon
                  sx={{
                    color: '#FFD43B',
                  }}
                />
              )}
            </div>
          </div>

          {nextDateAutoGenerate ? (
            <p
              style={{
                height: '54px',
                border: '1px solid #d7ddea',
                padding: '14px 16px',
                borderRadius: '12px',
              }}
            >
              {moment(nextDateAutoGenerate).format('DD-MM-YYYY')}
            </p>
          ) : (
            <DatePicker selectedDate={nextDate} dateChange={changeNextDate} />
          )}
        </div>

        {width <= 1050 && false && (
          <div className='width32Percent'>
            <p
              style={{
                marginBottom: '9px',
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              Номер договора
            </p>

            {nextDateAutoGenerate ? (
              <p
                style={{
                  height: '54px',
                  border: '1px solid #d7ddea',
                  padding: '14px 16px',
                  borderRadius: '12px',
                }}
              >
                {moment(nextDateAutoGenerate).format('DD-MM-YYYY')}
              </p>
            ) : (
              <Input
                placeholder={'Пример ввода - 00000-00'}
                type={'text'}
                notRequired={true}
                value={''}
                actions={{ change: () => {} }}
              />
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default ProtocolDate
