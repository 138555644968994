import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import RadioButton from '../../../../../ui/radio-button/index.jsx'
import { Typography, Button } from '@mui/material'
import { Wrapper } from '../../styles.ts'

import useWindowDimensions from '../../../../hooks/useWindowDimensions.js'
import { setName } from '../../../../../store/slices/app/controlers/pageName.js'

const Accepted = props => {
  const { title, description, value, onChange, label, notMatch } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const { width } = useWindowDimensions()
  const dispatch = useDispatch()

  const MAX_LENGTH = 113

  const isLongText = description.length > MAX_LENGTH

  const shortDescription = isLongText ? description.slice(0, MAX_LENGTH) : description

  const handleToggle = () => {
    setIsExpanded(prev => !prev)
  }

  useEffect(() => {
    dispatch(setName('Внешний осмотр и опробование'))
  }, [dispatch])

  return (
    <Wrapper style={width > 1050 ? {} : { gap: '15px' }}>
      <Typography variant='h2'>{title}</Typography>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Typography variant='subtitle2' style={{ wordBreak: 'break-word' }}>
          {!!!isExpanded ? shortDescription : description}
          {isLongText && !isExpanded && '...'}
        </Typography>

        {isLongText && (
          <Button
            variant='text'
            color='primary'
            onClick={handleToggle}
            style={{
              padding: 0,
              textTransform: 'none',
              alignSelf: 'flex-start',
              fontFamily: '"Wix Madefor Display", sans-serif',
              fontSize: '15px',
            }}
          >
            {isExpanded ? 'Свернуть' : 'Подробнее'}
          </Button>
        )}
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '16px' }}>
        <Typography variant='body2'>{label}</Typography>
        <RadioButton
          value={value}
          onChange={onChange}
          trueLabel={'Соответствует'}
          falseLabel={'Не соответствует'}
          trueValue={true}
          falseValue={false}
          threeBtn={title === 'Подтверждение соответствия программного обеспечения'}
          notMatch={notMatch}
        />
      </div>
    </Wrapper>
  )
}

export default Accepted
